import { ReactNode, createContext, useCallback, useMemo, useState } from 'react';
import { InvoiceFileProcessingStatusDto, InvoiceUploadSignedUrlDto, InvoiceUploadSignedUrlRequestDto } from 'src/generated/services/TFinancialApi';
import { useForm } from 'react-hook-form';
import { IUploadFormValues, UploadStep } from './types';

export interface IUploadSelectorProps {
  children: ReactNode;
}

export const UploadSelectorContext = createContext(null);

export const UploadSelector = ({ children }: IUploadSelectorProps) => {
  const [isShowUpload, setIsShowUpload] = useState<boolean>(false);
  const [serviceProvider, setServiceProvider] = useState<string>('');
  const [modality, setModality] = useState<InvoiceUploadSignedUrlRequestDto['modality']>(null);
  const [isMinimized, setIsMinimized] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<UploadStep>(UploadStep.Step1_SelectInvoice);
  const [uploadResults, setUploadResults] = useState<InvoiceUploadSignedUrlDto[]>([]);
  const [uploadProcessings, setUploadProcessings] = useState<InvoiceFileProcessingStatusDto[]>([]);
  const [batchId, setBatchId] = useState<string>('');
  const [uploadFinishItems, setUploadFinishItems] = useState<InvoiceUploadSignedUrlDto[]>([]);
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
  const [isAllProcessCompleted, setIsAllProcessCompleted] = useState<boolean>(false);
  const [errorProcessingStatuses, setErrorProcessingStatuses] = useState<string[]>([]);

  const defaultValues = {
    serviceProvider,
    modality,
    invoiceFiles: [],
  };

  const formContext = useForm({
    defaultValues: defaultValues as IUploadFormValues,
  });

  const onSetupUpload = useCallback((provider?: string, modality?: InvoiceUploadSignedUrlRequestDto['modality']) => {
    setIsShowUpload(true);
    if (provider) {
      setServiceProvider(provider);
    }
    if (modality) {
      setModality(modality);
    }
  }, []);

  const onCloseUpload = useCallback(() => {
    setIsShowUpload(false);
    setServiceProvider('');
    setModality(null);
  }, [setIsShowUpload]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onResetUpload = () => {
    formContext.reset();
    setIsMinimized(false);
    setUploadResults([]);
    setUploadFinishItems([]);
    setActiveStep(UploadStep.Step1_SelectInvoice);
  };

  const value = useMemo(
    () => ({
      isShowUpload,
      isMinimized,
      activeStep,
      modality,
      serviceProvider,
      uploadResults,
      uploadProcessings,
      uploadFinishItems,
      batchId,
      isLoadingUpload,
      isAllProcessCompleted,
      setIsMinimized,
      setActiveStep,
      setUploadResults,
      setBatchId,
      setUploadFinishItems,
      setIsLoadingUpload,
      onCloseUpload,
      onSetupUpload,
      onResetUpload,
      setUploadProcessings,
      setIsAllProcessCompleted,
      formContext,
      errorProcessingStatuses,
      setErrorProcessingStatuses,
    }),
    [
      isShowUpload,
      isMinimized,
      activeStep,
      modality,
      serviceProvider,
      uploadResults,
      uploadProcessings,
      uploadFinishItems,
      batchId,
      isLoadingUpload,
      setIsMinimized,
      isAllProcessCompleted,
      setActiveStep,
      setUploadResults,
      setBatchId,
      setUploadFinishItems,
      setIsLoadingUpload,
      onCloseUpload,
      onSetupUpload,
      onResetUpload,
      setUploadProcessings,
      setIsAllProcessCompleted,
      formContext,
      errorProcessingStatuses,
      setErrorProcessingStatuses,
    ]
  );

  return <UploadSelectorContext.Provider value={value}>{children}</UploadSelectorContext.Provider>;
};
