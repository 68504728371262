import { useNavigate } from 'react-router-dom';

interface PdfErrorComponentProps {
  errorMessage: string;
}

const PdfErrorComponent = ({ errorMessage }: PdfErrorComponentProps) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          padding: '20px',
          backgroundColor: '#f8d7da',
          color: '#721c24',
          borderRadius: '4px',
          marginBottom: '20px',
        }}
      >
        <h3>Error Loading PDF</h3>
        <p>{errorMessage}</p>
      </div>
      <button
        onClick={() => navigate(-1)}
        style={{
          padding: '10px 20px',
          backgroundColor: '#007bff',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
      >
        Go Back
      </button>
    </div>
  );
};

export default PdfErrorComponent;
