import { FC, useEffect, useMemo, useRef } from 'react';
import { useImmerReducer } from 'use-immer';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import { Chip } from '@mui/material';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

import { createAxiosInstance } from 'src/utils/createAxiosInstance';
import IconDocument from 'src/assets/images/icons/document.svg';
import IconInfo from 'src/assets/images/icons/info-circle.svg';
import { beautifyEnum, cn, keepFileAlphanumeric } from 'src/utils/utility';
import { InvoiceFileProcessingStatusDto, InvoiceUploadSignedUrlDto } from 'src/generated/services/TFinancialApi';
import IconRefresh from 'src/assets/images/icons/refresh.svg';
import IconArrowRight from 'src/assets/images/icons/chevron-right.svg';
import authSelectors from 'src/infra/stores/reducer/auth/selectors';
import { CustomTooltip } from 'src/presentations/components/atom/customtooltip';
import { COGNITO_GROUP } from 'src/infra/stores/reducer/auth/user';
import { IUploadFormValues } from './types';
import StatusChip from '../../organisms/dashboard/StatusChip';
import { simplifyFileNames } from './tools';
import { useUploadSelector } from './useUploadSelector';
import { processingStatuses } from './configs';

const axios = createAxiosInstance({}, []);

export interface IUploadStatusItemProps {
  data: InvoiceUploadSignedUrlDto;
  onFinish(data: InvoiceUploadSignedUrlDto): void;
  invoiceDetail: InvoiceFileProcessingStatusDto | null;
  isDisableUpload?: boolean;
}

interface FileProps {
  name: string;
  date: string;
}

interface UploadState {
  uploadPercentage: number;
  // fileUpload will be non-null while uploading
  fileUpload: FileProps;
  uploadError: string;
}

type UploadAction =
  | {
      type: 'uploading';
      payload: number;
    }
  | {
      type: 'showUploadingFile';
      payload: FileProps;
    }
  | {
      type: 'error';
      payload: string;
    }
  | {
      type: 'uploadComplete';
    };

const uploadReducer = (state: UploadState, action: UploadAction): UploadState => {
  switch (action.type) {
    case 'uploading':
      state.uploadPercentage = action.payload;
      return state;
    case 'showUploadingFile':
      state.uploadPercentage = 0;
      state.fileUpload = action.payload;
      state.uploadError = '';
      return state;
    case 'uploadComplete':
      state.fileUpload = null;
      return state;
    case 'error':
      state.fileUpload = null;
      state.uploadError = action.payload;
      return state;

    default:
      return state;
  }
};

const StyledChip = styled(Chip)({
  background: '#ffffff',
  border: '1px solid #D9D9D9',
  borderRadius: '20px',
  color: '#A3A3A3',

  '& .MuiChip-label': {
    paddingRight: '0px!important',
  },
});

const initialState: UploadState = {
  fileUpload: null,
  uploadError: '',
  uploadPercentage: 0,
};

export const UploadStatusItem: FC<IUploadStatusItemProps> = ({ data, onFinish, invoiceDetail, isDisableUpload }) => {
  const { watch } = useFormContext<IUploadFormValues>();
  const invoiceFiles = watch('invoiceFiles');
  const [state, dispatch] = useImmerReducer(uploadReducer, initialState);
  const uploadedRef = useRef<boolean>(false);
  const { preSignedURLs, headers, fileName } = data;
  const role = useSelector(authSelectors.selectUserRole);
  const { errorProcessingStatuses } = useUploadSelector();

  const btnStyles =
    'flex items-center gap-4 py-8 pl-16 pr-10 rounded-full text-neutral-70 font-medium text-14 border border-neutral-20 bg-white shadow-[0_2px_3px_0_rgba(0,0,0,0.1)]';

  useEffect(() => {
    if (preSignedURLs !== '' && !uploadedRef.current && !isDisableUpload) {
      uploadedRef.current = true;
      const file = invoiceFiles.find((file: File) => keepFileAlphanumeric(file?.name) === keepFileAlphanumeric(fileName));
      if (file) {
        axios
          .put(preSignedURLs, file, {
            onUploadProgress: (progressEvent) => {
              const percentage = Math.floor(Math.round((progressEvent.loaded * 100) / progressEvent.total));
              dispatch({ type: 'uploading', payload: percentage });
            },
            method: 'PUT',
            headers: {
              'Content-Type': 'application/pdf',
              ...headers,
            },
          })
          .then(() => {
            dispatch({ type: 'uploadComplete' });
            onFinish(data);
          })
          .catch((error) => {
            dispatch({ type: 'error', payload: error?.message || 'Error' });
            onFinish(data);
          });
      }
    }
  }, [data, dispatch, fileName, headers, invoiceFiles, onFinish, preSignedURLs, isDisableUpload]);

  const uploadStatus = useMemo(() => {
    if (isDisableUpload) {
      return 'Upload Completed';
    }
    if (state.uploadPercentage < 100) {
      return 'Uploading';
    }
    if (state.uploadPercentage === 100 && state.uploadError) {
      return 'Error';
    }
    if (state.uploadPercentage === 100 && !state.uploadError) {
      return 'Upload Completed';
    }
    return 'Waiting';
  }, [state, isDisableUpload]);
  const isErrorProcessing = useMemo(() => errorProcessingStatuses.includes(invoiceDetail?.status), [invoiceDetail, errorProcessingStatuses]);
  const isProcessing = useMemo(() => processingStatuses.includes(invoiceDetail?.status), [invoiceDetail]);

  const isButtonDisabled: boolean = useMemo(() => {
    let isDisabled = true;
    if (invoiceDetail) {
      if (invoiceDetail?.isNoRateCardFound && role === COGNITO_GROUP.PROCUREMENT_MANAGER) {
        isDisabled = false;
      } else if (invoiceDetail?.invoiceId) {
        isDisabled = false;
      }
    }
    return isDisabled;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceDetail]);

  return (
    <div className='flex gap-10 p-16 rounded-6 bg-neutral-10'>
      <div className='w-36'>
        <img src={IconDocument} alt='' />
      </div>
      <div className='flex-1 flex flex-col gap-6'>
        <div className='flex items-center gap-16'>
          <div className='flex-1'>{simplifyFileNames(fileName)}</div>
          <div className='ml-auto text-12 font-medium flex items-center gap-12'>
            <span className={state.uploadError !== '' ? 'text-red-40' : 'text-primary'}>{uploadStatus}</span>
            <span className='w-1 h-[9px] bg-neutral-20' />
            <span className='text-blue_grey-50'>{isDisableUpload ? 100 : state.uploadPercentage}%</span>
          </div>
        </div>

        <div className='relative h-5'>
          <div className='h-full w-full rounded-30 bg-neutral-20' />
          <div
            className={cn('h-full w-full rounded-30 z-10 absolute left-0 top-0', state.uploadError !== '' ? 'bg-red-40' : 'bg-primary')}
            style={{ width: `${isDisableUpload ? 100 : state.uploadPercentage}%` }}
          />
        </div>

        <div className='mt-6 flex items-center gap-16'>
          <div className='text-12 text-neutral-70'>Invoice status after processing:</div>
          <div className='flex items-center gap-8'>
            {isProcessing && !isErrorProcessing ? (
              <Chip
                label={
                  <div className='flex items-center px-4 gap-8'>
                    <span>Processing</span>
                    <img src={IconRefresh} className='animate-spin' alt='' />
                  </div>
                }
                style={{
                  background: '#ffffff',
                  border: '1px solid #D9D9D9',
                  borderRadius: '20px',
                  color: '#A3A3A3',
                }}
                size='small'
              />
            ) : (
              <>
                {isErrorProcessing ? (
                  <>
                    <StyledChip
                      label={
                        <div className='flex items-center px-4 gap-8'>
                          <span className='font-medium'>Under Review</span>
                          <CustomTooltip title='Under investigation by the Support Team who will get in touch with you shortly'>
                            <img width={20} src={IconInfo} alt='' />
                          </CustomTooltip>
                        </div>
                      }
                      style={{
                        background: 'rgba(89, 114, 130, 0.12)',
                        border: '1px solid #597282',
                        borderRadius: '20px',
                        color: '#597282',
                      }}
                      size='small'
                    />
                  </>
                ) : (
                  <>
                    <StatusChip status={invoiceDetail?.invoiceStatus} size='small' />

                    {/* <Link to={`/action-details/${invoiceDetail.invoiceId}`} className="text-primary font-medium text-14">See Invoice</Link> */}
                    {invoiceDetail?.isNoRateCardFound && (
                      <>
                        <div className='w-4 h-4 rounded-full bg-[#D9D9D9]' />
                        <div className='text-neutral-60 text-12'>No Rate Card Found</div>
                      </>
                    )}
                    {invoiceDetail?.invoiceStatus === 'REJECTED' && (
                      <>
                        <div className='w-4 h-4 rounded-full bg-[#D9D9D9]' />
                        <div className='text-neutral-60 text-12'>{beautifyEnum(invoiceDetail?.finalResolution)}</div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>

          <div className='ml-auto text-12 text-neutral-70'>
            Latest update {invoiceDetail?.updatedOn ? format(new Date(invoiceDetail?.updatedOn), 'dd-MMM-yyyy hh.mm a') : '-'}
          </div>
        </div>
      </div>
      <div className='pl-20 flex items-center justify-end w-[160px]'>
        {!isButtonDisabled ? (
          <Link to={`/action-details/${invoiceDetail.invoiceId}?hideBack=1`} className={btnStyles} target='_blank'>
            <span>See Invoice</span>
            <img src={IconArrowRight} alt='' />
          </Link>
        ) : (
          <button type='button' disabled className={clsx(btnStyles, 'opacity-40')}>
            <span>{invoiceDetail?.isNoRateCardFound ? 'Review' : 'See Invoice'}</span>
            <img src={IconArrowRight} alt='' />
          </button>
        )}
      </div>
    </div>
  );
};
