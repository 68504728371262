import { FormContainer, useForm } from 'react-hook-form-mui';
import { ModelConfigurationInvoiceDataPipelineConfig } from './InvoiceDataPipelineConfig.tsx';

export const ModelConfigurationMain = () => {
  const formContext = useForm();
  return (
    <>
      <div className='flex border border-neutral-20 bg-[#fafafa] rounded-16 shadow-[0_10px_24px_0_rgba(0,0,0,0.1)]'>
        <FormContainer formContext={formContext}>
          <ModelConfigurationInvoiceDataPipelineConfig />
        </FormContainer>
      </div>
    </>
  );
};
