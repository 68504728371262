import React from 'react';

/**
 * Highlights occurrences of a keyword within a text string
 * @param text The original text to search within
 * @param keyword The keyword to highlight
 * @returns React elements with highlighted text
 */
export const highlightText = (text: string, keyword: string): React.ReactNode => {
  if (!keyword || !text) {
    return text;
  }

  const regex = new RegExp(`(${keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');
  const parts = text.split(regex);

  return parts.map((part) => {
    if (part.toLowerCase() === keyword.toLowerCase()) {
      return (
        <span key={`${part}-highlighted`} className='bg-[#FFFEC8] font-medium'>
          {part}
        </span>
      );
    }
    return part;
  });
};
