/**
 * ModelConfiguration
 */
import { LandingTopWidget } from 'src/presentations/components/organisms/landing';
import { ModelConfigurationPage } from '../../presentations/components/pages/modelConfiguration/ModelConfiguration.tsx';

const ModelConfiguration: React.FC = () => (
  <div className='bg-neutral-10'>
    <div className='w-full'>
      <LandingTopWidget />
    </div>
    <ModelConfigurationPage />
  </div>
);

export default ModelConfiguration;
