import { MRT_ColumnDef, MRT_Row, MRT_TableOptions, useMaterialReactTable } from 'material-react-table';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { InvoiceDataPipelineConfigDto, InvoiceDataPipelineConfigMetadataDto, InvoiceDataPipelineConfigRequestDto } from 'src/generated/services/TFinancialApi';
import { openToast, openToastError } from 'src/use-cases/toast/useToast';
import { useFormContext } from 'react-hook-form';
import Button from '../../atom/button';
import { CustomTable } from '../../molecules/table/Table';
import { newFormatDateTimeString } from '../../../../utils/date.ts';
import {
  useDeleteInvoiceDataPipelineConfig,
  useGetInvoiceDataPipelineConfigMetaData,
  useGetInvoiceDataPipelineConfigs,
  useSaveInvoiceDataPipelineConfig,
} from '../../../../use-cases/invoiceDataPipelineConfig/useInvoiceDataPipelineConfig.ts';
import { beautifyEnum } from '../../../../utils/utility.ts';
import InputMultipleSelect from '../../atom/form/input/Multiselect.tsx';

const StyledBox = styled(Box)({
  '& .MuiFormControl-root': {
    width: '180px',
  },
  '& .MuiInputBase-root': {
    borderRadius: '8px',
  },
});

function validateRequired(value: any): boolean {
  return value !== null && value !== '';
}
const validateData = (data: InvoiceDataPipelineConfigDto) => ({
  dataPipelineConfigId: !validateRequired(data.dataPipelineConfigId) ? 'Data Pipeline Config ID is Required' : '',
});

const invoiceDataPipelineConfigFilterMapper = (invoiceDataPipelineConfigFilter: InvoiceDataPipelineConfigMetadataDto) => [
  {
    name: `serviceProviderCodes`,
    label: 'Service Provider',
    options: invoiceDataPipelineConfigFilter?.serviceProviders.map((value) => ({
      value: value?.code,
      label: beautifyEnum(value?.code).toUpperCase(),
    })),
  },
  {
    name: `modalities`,
    label: 'Modality',
    options: invoiceDataPipelineConfigFilter?.modalities.map((value) => ({
      value,
      label: beautifyEnum(value).toUpperCase(),
    })),
  },
  {
    name: `modelIds`,
    label: 'Model Id',
    options: invoiceDataPipelineConfigFilter?.modelIds.map((value) => ({
      value,
      label: value,
    })),
  },
];

export const ModelConfigurationInvoiceDataPipelineConfig = () => {
  const formHook = useFormContext();
  const { setValue } = formHook;

  const [filter, setFilter] = useState<InvoiceDataPipelineConfigRequestDto>({
    modalities: [],
    serviceProviderCodes: [],
    modelIds: [],
  });

  // Inside your component
  const { data: invoiceDataPipelineConfigMetadataDto, isLoading, isFetching } = useGetInvoiceDataPipelineConfigMetaData();

  const filterAccordions = invoiceDataPipelineConfigFilterMapper(invoiceDataPipelineConfigMetadataDto?.data);

  const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});

  const columns = useMemo(
    (): MRT_ColumnDef<InvoiceDataPipelineConfigDto>[] => [
      {
        enableEditing: false,
        accessorKey: 'no',
        header: 'NO',
      },
      {
        accessorKey: 'serviceProviderCode',
        header: 'SERVICE PROVIDER',
        editVariant: 'select',
        editSelectOptions: [
          { value: '', label: '[EMPTY]' },
          ...(invoiceDataPipelineConfigMetadataDto?.data?.serviceProviders.map((value) => ({
            value: value?.code,
            label: beautifyEnum(value?.code).toUpperCase(),
          })) || []),
        ],
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.serviceProviderCode,
          helperText: validationErrors?.serviceProviderCode,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              serviceProviderCode: undefined,
            }),
        },
        Cell: ({ cell }) => beautifyEnum(cell.row.original.serviceProviderCode).toUpperCase(),
      },
      {
        accessorKey: 'dataPipelineConfigId',
        header: 'DATA PIPELINE CONFIG ID',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.dataPipelineConfigId,
          helperText: validationErrors?.dataPipelineConfigId,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              dataPipelineConfigId: undefined,
            }),
        },
      },
      {
        accessorKey: 'modality',
        header: 'MODALITY',
        editVariant: 'select',
        editSelectOptions: [
          { value: '', label: '[EMPTY]' },
          ...(invoiceDataPipelineConfigMetadataDto?.data?.modalities.map((value) => ({
            value,
            label: beautifyEnum(value).toUpperCase(),
          })) || []),
        ],
        muiEditTextFieldProps: {
          select: true,
          required: true,
          error: !!validationErrors?.modality,
          helperText: validationErrors?.modality,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              modality: undefined,
            }),
        },
      },
      {
        accessorKey: 'modelId',
        header: 'MODEL ID',
        muiEditTextFieldProps: {
          required: false,
          error: !!validationErrors?.modelId,
          helperText: validationErrors?.modelId,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              modelId: undefined,
            }),
        },
      },
      {
        accessorKey: 'updatedOn',
        header: 'UPDATED ON',
        enableEditing: false,
        Cell: ({ cell }) => newFormatDateTimeString(cell.row.original.updatedOn),
      },
    ],
    [invoiceDataPipelineConfigMetadataDto?.data?.modalities, invoiceDataPipelineConfigMetadataDto?.data?.serviceProviders, validationErrors]
  );

  const configs = useGetInvoiceDataPipelineConfigs(filter);
  const { data, isLoading: isDataLoading, isFetching: isDataFetching } = configs;
  const invoiceDataPipelineConfigs = useMemo(() => {
    if (data?.data && data?.data?.length > 0) {
      return data?.data?.map((item, index) => ({
        no: index + 1,
        serviceProviderCode: item?.serviceProviderCode,
        dataPipelineConfigId: item?.dataPipelineConfigId,
        modality: item?.modality,
        modelId: item?.modelId,
        updatedOn: item?.updatedOn,
        serviceProviderName: item?.serviceProviderName,
        id: item?.id,
      }));
    }
    return [];
  }, [data]);
  const [tableData, setTableData] = useState<InvoiceDataPipelineConfigDto[]>([]);
  useEffect(() => {
    setTableData(invoiceDataPipelineConfigs);
  }, [invoiceDataPipelineConfigs]);

  const { mutateAsync: updateInvoiceDataPipelineConfig } = useSaveInvoiceDataPipelineConfig(filter);
  const { mutateAsync: deleteInvoiceDataPipelineConfig } = useDeleteInvoiceDataPipelineConfig(filter);

  // CREATE action
  const handleCreateInvoiceDataPipelineConfig: MRT_TableOptions<InvoiceDataPipelineConfigDto>['onCreatingRowSave'] = ({ values, table }) => {
    Object.keys(values).forEach((key) => {
      if (values[key] === '-' || values[key] === '') {
        values[key] = null;
      }
    });

    const newValidationErrors = validateData(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }

    updateInvoiceDataPipelineConfig(values)
      .then(() => {
        openToast({
          toastType: 'success',
          title: 'Success',
          description: 'Invoice Data Pipeline Config created!',
        });
        setValidationErrors({});
        table.setCreatingRow(null);
      })
      .catch(() => {
        openToastError(new Error('Invoice Data Pipeline Config creation failed'));
      });
  };

  // UPDATE action
  const handleUpdateInvoiceDataPipelineConfig: MRT_TableOptions<InvoiceDataPipelineConfigDto>['onEditingRowSave'] = async ({ row, values, table }) => {
    // Change all "-" to null
    Object.keys(values).forEach((key) => {
      if (values[key] === '-' || values[key] === '') {
        values[key] = null;
      }
    }) as any;

    const newValidationErrors = validateData(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }

    try {
      await updateInvoiceDataPipelineConfig({
        ...values,
        id: row.original.id,
      });

      openToast({
        toastType: 'success',
        title: 'Success',
        description: 'Invoice Data Pipeline Config updated!',
      });
      table.setEditingRow(null);
    } catch (error) {
      openToastError(new Error('Invoice Data Pipeline Config update failed'));
    }
  };

  // DELETE action
  const openDeleteConfirmModal = async (row: MRT_Row<InvoiceDataPipelineConfigDto>) => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to delete this Invoice Data Pipeline Config?')) {
      try {
        await deleteInvoiceDataPipelineConfig(row.original.id);
        openToast({
          toastType: 'success',
          title: 'Success',
          description: 'Invoice Data Pipeline Config deleted!',
        });
      } catch (error) {
        openToastError(new Error('Invoice Data Pipeline Config delete failed'));
      }
    }
  };
  const table = useMaterialReactTable({
    enableSorting: true,
    enablePinning: false,
    enableRowDragging: false,
    enableColumnActions: false,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableStickyHeader: true,
    muiTableContainerProps: { sx: { height: '100%' } },
    muiTablePaperProps: { sx: { height: '100%' } },
    enableEditing: true,
    createDisplayMode: 'row',
    editDisplayMode: 'row',
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateInvoiceDataPipelineConfig,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleUpdateInvoiceDataPipelineConfig,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    positionActionsColumn: 'first',
    data: tableData,
    columns,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title='Edit'>
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title='Delete'>
          <IconButton color='error' onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    // muiTableBodyRowProps: ({ row }) => ({
    //   selected: row.original.id === shippingServiceId,
    //   onClick: () => {
    //     setShippingServiceId(row.original.id);
    //   },
    //   sx: {
    //     cursor: 'pointer',
    //   },
    // }),
  });

  // useEffect(() => {
  //   if (getInvoiceDataPipelineConfigsStatus === 'success' && invoiceDataPipelineConfigs.length > 0) {
  //     setShippingServiceId(invoiceDataPipelineConfigs[0].id);
  //   } else {
  //     setShippingServiceId('');
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getInvoiceDataPipelineConfigsStatus, billedPartyId, serviceProviderId, setShippingServiceId]);

  const handleFilterChange = (name: string, value: any) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };
  return (isLoading || isFetching) && (isDataLoading || isDataFetching) ? (
    <CircularProgress color='primary' />
  ) : (
    <div className='flex-1 bg-white rounded-r-16 border-neutral-20 border-l-1 overflow-auto'>
      <div className='flex flex-col'>
        <div className='flex items-center h-[4rem] border-b border-neutral-20 px-20'>
          <div className='flex-1 flex items-center'>
            <h3 className='text-14 text-neutral-50 font-medium overflow-hidden'>Invoice Data Pipeline Config</h3>
          </div>
          <StyledBox className='items-center flex'>
            <div className='p-[12px] w-full h-fit flex flex-col gap-[16px]'>
              <div className='flex flex-row gap-[16px]'>
                {filterAccordions?.map((option) => (
                  <div className='relative w-full' key={`filter-option-${option?.name}`}>
                    <InputMultipleSelect
                      name={option?.name}
                      formHook={formHook}
                      data={option?.options || []}
                      placeholder={option?.label}
                      onReset={() => {
                        setValue(option?.name, null);
                        handleFilterChange(option?.name, []);
                      }}
                      onChange={(value) => {
                        setValue(option?.name, value);
                        handleFilterChange(option?.name, value);
                      }}
                      // disabled={!!option?.options?.find((o) => o.value === modality)}
                      isShowSearch
                    />
                  </div>
                ))}
              </div>
            </div>
            <Button
              variant='primary'
              className='!pr-12 !pl-16 !ml-12'
              onClick={() => {
                table.setCreatingRow(true);
              }}
            >
              <div className='flex items-center gap-10'>
                New Invoice Data Pipeline Config
                <AddIcon />
              </div>
            </Button>
          </StyledBox>
        </div>
        <div className='border-t border-t-white border-b border-b-neutral-20 py-16 px-12 h-[400px] overflow-auto'>
          <CustomTable table={table} tableContainerProps={{ sx: { height: '100%' } }} />
        </div>
      </div>
    </div>
  );
};
