import { useParams } from 'react-router-dom';
import { useGetInvoiceReport } from 'src/use-cases/invoice/useInvoices';
import { PageLoader } from 'src/presentations/components/molecules/pageLoader/PageLoader';
import PdfViewerComponent from 'src/presentations/components/molecules/pdfViewer/PdfViewerComponent';
import PdfErrorComponent from 'src/presentations/components/molecules/pdfViewer/PdfErrorComponent';
import { usePdfProcessor } from 'src/hooks/usePdfProcessor';

const InvoiceReportViewer = () => {
  const { invoiceId } = useParams();
  const { refetch: refetchInvoiceReport } = useGetInvoiceReport(invoiceId);

  const { pdfUrl, error, isLoading } = usePdfProcessor({
    id: invoiceId,
    idName: 'Invoice ID',
    fetchFunction: refetchInvoiceReport,
    errorPrefix: 'invoice report',
  });

  if (error) {
    return <PdfErrorComponent errorMessage={error} />;
  }

  if (isLoading || !pdfUrl) {
    return <PageLoader />;
  }

  return <PdfViewerComponent pdfUrl={pdfUrl} onError={(errorMessage) => console.error(errorMessage)} downloadFileName={`invoice-report-${invoiceId}`} />;
};

export default InvoiceReportViewer;
