/**
 * Forbidden Page
 */
import Icon403 from 'src/assets/images/icons/empty-notification.svg';
import Button from 'src/presentations/components/atom/button';
import { LandingTopWidget } from 'src/presentations/components/organisms/landing';

const Forbidden: React.FC = () => (
  <div className='w-screen h-screen flex flex-col bg-neutral-10'>
    <div className='w-full'>
      <LandingTopWidget />
    </div>
    <div className='flex-1 flex flex-col items-center justify-center bg-neutral-10'>
      <div className=''>
        <img className='w-[12.5rem]' src={Icon403} alt='' />
      </div>
      <h1 className='text-60 text-neutral-50 font-bold'>403</h1>
      <h2 className='text-24 font-bold text-[#202020]'>Access Forbidden</h2>
      <h2 className='text-16 text-neutral-60'>You don&lsquo;t have permission to access this page</h2>
      <div className='mt-36'>
        <Button path='/'>Back to Home</Button>
      </div>
    </div>
  </div>
);

export default Forbidden;
