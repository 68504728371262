import { Link } from 'react-router-dom';
import IconArrow from 'src/assets/images/icons/arrow-left_ico.svg';
import { ModelConfigurationMain } from '../../organisms/modelConfiguration/Main.tsx';

export const ModelConfigurationPage = () => (
  <div className='relative'>
    <div className='flex items-center gap-24 py-24 px-32 bg-white shadow-[0_6px_20px_0_rgba(0,0,0,0.04)]'>
      <div className='flex items-center gap-18'>
        <Link
          to='/'
          className='w-40 h-40 rounded-full bg-neutral-10 border border-neutral-20 transition-all duration-300 hover:border-neutral-40 flex items-center justify-center'
        >
          <img src={IconArrow} alt='Back' />
        </Link>
        <div className='flex flex-col'>
          <h2 className='text-24 font-bold text-blue_grey-90'>Model Configuration</h2>
          <p className='text-14 text-neutral-60'>Overview</p>
        </div>
      </div>
    </div>

    <div className='px-32 py-24'>
      <ModelConfigurationMain />
    </div>
  </div>
);
