import { ThemeProvider, createTheme } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@mui/material/Typography';
import { InvoiceDto } from 'src/generated/services/TFinancialApi';
import { styled, Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material';
import React, { useMemo } from 'react';

// Define a custom theme
const theme = createTheme();

interface StyledTooltipProps extends MuiTooltipProps {
  className?: string;
}

// Customize the tooltip
const Tooltip = styled(({ className, ...props }: StyledTooltipProps) => <MuiTooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: theme.palette.common.black,
    color: 'white',
  },
  '& .MuiTooltip-arrow': {
    color: theme.palette.common.black,
  },
}));

interface IDetailCellProps {
  data: InvoiceDto;
}

const DetailCell: React.FC<IDetailCellProps> = ({ data }) => {
  const { invoiceStatusOverview } = data;
  const hasDetails = invoiceStatusOverview?.details.length > 0;

  const label = invoiceStatusOverview?.overview;

  const tooltipItems = useMemo(() => {
    const items = [];
    const approvalItems = invoiceStatusOverview?.details.filter((item) => ['service', 'accessorial'].find((str) => item.toLowerCase().includes(str)));
    const deviationsItems = invoiceStatusOverview?.details
      .filter((item) => !['service', 'accessorial'].some((str) => item.toLowerCase().includes(str)))
      .map((item) => (item.includes('New Charge') ? item.replace('New Charge', 'Unknown Charge') : item));

    if (deviationsItems?.length > 0) {
      items.push(deviationsItems);
    }

    if (approvalItems?.length > 0) {
      items.push(approvalItems);
    }

    return items.map((item) => (
      <div className='flex flex-col gap-[3px] border-b border-[rgba(255,255,255,0.4)] pb-8 mb-8 last:border-b-0 last:pb-0 last:mb-0' key={item.join('-')}>
        {item?.map((item2: string) => (
          <Typography key={item2} style={{ fontSize: '12px' }} variant='body2'>
            {item2}
          </Typography>
        ))}
      </div>
    ));
  }, [invoiceStatusOverview]);

  const formattedLabel = useMemo(
    () =>
      label?.split(', ').map((item) => (
        <React.Fragment key={`${data.invoiceNumber}-${item.replace(/\s+/g, '')}`}>
          {item}
          <br />
        </React.Fragment>
      )),
    [data.invoiceNumber, label]
  );

  return (
    <ThemeProvider theme={theme}>
      <div className='flex items-center gap-2.5 justify-center text-left'>
        <span style={{ cursor: 'pointer', color: '#202020' }}>{formattedLabel}</span>
        {hasDetails && (
          <Tooltip title={<div style={{ display: 'flex', flexDirection: 'column', padding: '4px' }}>{tooltipItems}</div>} placement='bottom' arrow>
            <InfoOutlinedIcon fontSize='small' style={{ verticalAlign: 'bottom', color: '#202020' }} />
          </Tooltip>
        )}
      </div>
    </ThemeProvider>
  );
};

export default DetailCell;
