import { FC, useMemo, useCallback, useState } from 'react';
import { Button } from '@mui/material';
import { InvoiceFileProcessingStatusDto, InvoiceUploadSignedUrlDto } from 'src/generated/services/TFinancialApi';

import IconCollapse from 'src/assets/images/icons/grid-2.svg';
import IconExpand from 'src/assets/images/icons/list.svg';

import { UploadStatusItem } from './UploadStatusItem';
import { useUploadSelector } from './useUploadSelector';
import { UploadSectionProvider } from './SectionProviders';
import { IGroupingItemData, IGroupingItems } from './types';
import { UploadSectionLoader } from './SectionLoader';
import { processingStatuses } from './configs';
import { UploadSectionUnderReview } from './SectionUnderReview';

/**
 * Callback function to handle the completion of the upload process.
 */
export interface IStepUploadStatusProps {
  onFinishUpload(data: InvoiceUploadSignedUrlDto): void;
}

interface IUploadResultItems {
  processing: IGroupingItemData[];
  underReview: IGroupingItemData[];
  completed: IGroupingItems;
}

/**
 * StepUploadStatus component
 */
export const StepUploadStatus: FC<IStepUploadStatusProps> = ({ onFinishUpload }) => {
  const { uploadResults, uploadProcessings, errorProcessingStatuses } = useUploadSelector();
  const [, setIsCollapseAll] = useState(false);
  const [isExpandAll, setIsExpandAll] = useState(false);

  // Function to check if a given status is a processing status.
  const checkIsProcessing = useCallback((status: string) => processingStatuses.includes(status), []);

  // Function to check if a given status is an error processing status.
  const checkIsErrorProcessing = useCallback((status: string) => errorProcessingStatuses.includes(status), [errorProcessingStatuses]);

  // Function to check if a given item is under review.
  const checkIsUnderReview = useCallback(
    (item: InvoiceFileProcessingStatusDto) => {
      const isError = errorProcessingStatuses.includes(item?.status);
      return isError && item?.fileResolutionStatus === 'UNDER_REVIEW';
    },
    [errorProcessingStatuses]
  );

  const uploadResultItems: IUploadResultItems = useMemo(() => {
    const processingItems: IGroupingItemData[] = [];
    const underReviewItems: IGroupingItemData[] = [];
    const completedItems: IGroupingItems = {};
    uploadResults?.forEach((result: InvoiceUploadSignedUrlDto) => {
      const invoiceDetail = uploadProcessings?.find(
        (item: InvoiceFileProcessingStatusDto) => item?.invoiceFileProcessingId === result?.dataPipelineProcessingId
      );
      if (invoiceDetail) {
        const itemData: IGroupingItemData = { ...result, invoiceDetail };
        const serviceProviderCode = invoiceDetail?.serviceProviderCode;
        const status = invoiceDetail?.status;
        const isProcessing = checkIsProcessing(status);
        const isError = checkIsErrorProcessing(status);
        const isUnderReview = checkIsUnderReview(invoiceDetail);

        if (isProcessing && !isError) {
          processingItems.push(itemData);
        } else if (isUnderReview && !serviceProviderCode) {
          underReviewItems.push(itemData);
        } else if (isError && !isUnderReview) {
          underReviewItems.push(itemData);
        } else if (serviceProviderCode) {
          completedItems[serviceProviderCode] = completedItems[serviceProviderCode] || [];
          completedItems[serviceProviderCode].push(itemData);
        }
      }
    });
    return {
      processing: processingItems,
      underReview: underReviewItems,
      completed: completedItems,
    };
  }, [uploadResults, uploadProcessings, checkIsProcessing, checkIsErrorProcessing, checkIsUnderReview]);

  return (
    <div className='relative'>
      <div className='flex items-center justify-between'>
        <div className='flex-1'>
          <h3 className='text-18 font-bold mb-4'>Upload & Processing Status: {uploadResults?.length || 0} Items</h3>
          <p className='text-14'>Feel free to close this window while invoices are being processed.</p>
        </div>

        <div className='ml-auto flex items-center gap-16'>
          <Button
            type='button'
            variant='outlined'
            color='inherit'
            onClick={() => {
              setIsCollapseAll(true);
              setIsExpandAll(false);
            }}
            sx={{ textTransform: 'capitalize', borderColor: '#dfdfdf', backgroundColor: '#fff', gap: '12px' }}
          >
            Collapse All
            <img src={IconCollapse} alt='' />
          </Button>
          <Button
            type='button'
            variant='outlined'
            color='inherit'
            onClick={() => {
              setIsExpandAll(true);
              setIsCollapseAll(false);
            }}
            sx={{ textTransform: 'capitalize', borderColor: '#dfdfdf', backgroundColor: '#fff', gap: '12px' }}
          >
            Expand All
            <img src={IconExpand} alt='' />
          </Button>
        </div>
      </div>

      <div className='mt-24 flex flex-col gap-24'>
        {Object.entries(uploadResultItems?.completed).length > 0 &&
          Object.entries(uploadResultItems?.completed).map(([, results]) => (
            <UploadSectionProvider data={results} defaultExpanded={isExpandAll}>
              {results.map((result) => (
                <UploadStatusItem
                  isDisableUpload
                  key={`result-${result?.fileName}`}
                  data={result}
                  onFinish={onFinishUpload}
                  invoiceDetail={result.invoiceDetail || null}
                />
              ))}
            </UploadSectionProvider>
          ))}

        {uploadResultItems?.underReview?.length > 0 && (
          <UploadSectionUnderReview defaultExpanded={isExpandAll} data={uploadResultItems?.underReview}>
            {uploadResultItems?.underReview?.map((result) => (
              <UploadStatusItem
                isDisableUpload
                key={`result-${result?.fileName}`}
                data={result}
                onFinish={onFinishUpload}
                invoiceDetail={result?.invoiceDetail || null}
              />
            ))}
          </UploadSectionUnderReview>
        )}
        {uploadResultItems?.processing?.length > 0 && (
          <UploadSectionLoader defaultExpanded={isExpandAll}>
            {uploadResultItems?.processing?.map((result) => (
              <UploadStatusItem key={`result-${result?.fileName}`} data={result} onFinish={onFinishUpload} invoiceDetail={result?.invoiceDetail || null} />
            ))}
          </UploadSectionLoader>
        )}
      </div>
    </div>
  );
};
