import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import IconDocument from 'src/assets/images/icons/document.svg';
import TransportMediumIcon from 'src/presentations/components/molecules/card/TransportMediumIcon';
import StatusChip from 'src/presentations/components/organisms/dashboard/StatusChip';
import { highlightText } from 'src/utils/highlightText';

import { IResultItemProps } from './types';

export const ResultItem: FC<IResultItemProps> = ({ result, keyword }) => {
  const { invoiceId, shipmentInvoiceId, modality, serviceProviderCode, invoiceNumber, shipmentNumber, invoiceStatus } = result;

  const modalityTitle = useMemo(() => {
    switch (modality) {
      case 'CT':
        return 'CONTROL TOWER';
      case 'COURIER':
        return 'COURIER';
      default:
        return modality;
    }
  }, [modality]);

  return (
    <Link
      to={`/action-details/${invoiceId}?shipment=${shipmentInvoiceId}`}
      target='_blank'
      className='flex items-center gap-16 py-12 px-16 hover:bg-neutral-10 rounded-12 transition-all duration-300'
    >
      <div className='w-36 relative'>
        <img src={IconDocument} alt='' className='w-full' />
        <div className='absolute bottom-0 right-0'>
          <TransportMediumIcon medium={modality} iconColor='#9F9F9F' size='sm' />
        </div>
      </div>

      <div className='flex-1 flex flex-col gap-4'>
        <div className='flex items-center gap-8'>
          <strong className='text-16 font-bold'>{serviceProviderCode}</strong>
          <div className='w-4 h-4 bg-blue_grey-30 rounded-full' />
          <strong className='text-14 font-medium text-grey-40'>{modalityTitle}</strong>
          <div className='w-4 h-4 bg-blue_grey-30 rounded-full' />
          <div className='text-14'>{highlightText(invoiceNumber, keyword || '')}</div>
        </div>

        <p className='text-12 text-neutral-60'>{highlightText(shipmentNumber, keyword || '')}</p>
      </div>

      <div className='ml-auto'>
        <StatusChip status={invoiceStatus} />
      </div>
    </Link>
  );
};
