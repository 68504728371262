import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import {
  InvoiceDataPipelineConfigDto,
  InvoiceDataPipelineConfigRequestDto,
  ResponseDtoInvoiceDataPipelineConfigMetadataDto,
  ResponseDtoListInvoiceDataPipelineConfigDto,
} from 'src/generated/services/TFinancialApi';
import { tFinancialService } from 'src/infra/services/tFinancialService';
import { cacheKeysUtil } from 'src/utils/cacheKeysUtil';

export const useGetInvoiceDataPipelineConfigs = (filter: InvoiceDataPipelineConfigRequestDto): UseQueryResult<ResponseDtoListInvoiceDataPipelineConfigDto> =>
  useQuery(cacheKeysUtil.getInvoiceDataPipelineConfigs(filter), async (): Promise<ResponseDtoListInvoiceDataPipelineConfigDto> => {
    const response = await tFinancialService.api.getInvoiceDataPipelineConfigs(filter);
    return response.data;
  });

export const useGetInvoiceDataPipelineConfigMetaData = (): UseQueryResult<ResponseDtoInvoiceDataPipelineConfigMetadataDto> =>
  useQuery(cacheKeysUtil.getInvoiceDataPipelineConfigMetaData(), async (): Promise<ResponseDtoInvoiceDataPipelineConfigMetadataDto> => {
    const response = await tFinancialService.api.getInvoiceDataPipelineConfigMetaData();
    return response.data;
  });

export const useSaveInvoiceDataPipelineConfig = (filter: InvoiceDataPipelineConfigRequestDto) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (dataPipelineConfigDto: InvoiceDataPipelineConfigDto) => tFinancialService.api.createInvoiceDataPipelineConfig(dataPipelineConfigDto),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(cacheKeysUtil.getInvoiceDataPipelineConfigs(filter)),
          queryClient.invalidateQueries(cacheKeysUtil.getInvoiceDataPipelineConfigMetaData()),
        ]);
      },
    }
  );
};

export const useDeleteInvoiceDataPipelineConfig = (filter: InvoiceDataPipelineConfigRequestDto) => {
  const queryClient = useQueryClient();

  return useMutation(async (id: string) => tFinancialService.api.deleteInvoiceDataPipelineConfig(id), {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(cacheKeysUtil.getInvoiceDataPipelineConfigs(filter)),
        queryClient.invalidateQueries(cacheKeysUtil.getInvoiceDataPipelineConfigMetaData()),
      ]);
    },
  });
};
