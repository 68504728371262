/**
 * Permission types enum
 */
export enum PermissionType {
  AUDIT = 'AUDIT',
  OPERATION = 'OPERATION',
  FINANCE = 'FINANCE',
  REPORT = 'REPORT',
  PROCUREMENT = 'PROCUREMENT',
  DISPUTE = 'DISPUTE',
  PERFORMANCE = 'PERFORMANCE',
}
