import Skeleton from '@mui/material/Skeleton';

export const LandingLoader = () => (
  <div className='space-y-4'>
    {[1, 2, 3, 4, 5].map((index) => (
      <div
        key={`loader-${index}`}
        className='flex items-center rounded-[10px]'
        style={{
          backgroundColor: '#F5F6FA',
          padding: '10px 16px',
          marginBottom: '1.5rem',
        }}
      >
        <Skeleton animation='wave' variant='circular' width={36} height={36} style={{ backgroundColor: '#DFDFDF' }} />
        <div className='flex-1 flex items-center ml-4'>
          <Skeleton animation='wave' height={14} width='20%' style={{ backgroundColor: '#DFDFDF', borderRadius: '4px', marginLeft: '20px' }} />
          <Skeleton animation='wave' variant='circular' height={15} width={15} style={{ backgroundColor: '#DFDFDF', borderRadius: '50%', marginLeft: '5px' }} />
        </div>
      </div>
    ))}
  </div>
);
