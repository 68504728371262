import { FC } from 'react';

import IconInfo from 'src/assets/images/icons/info-circle-dark.svg';
import { IUploadSectionUnderReviewProps } from './types';
import { UploadSection } from './Section';

//
// This component displays a section under review with a header and a list of items.
//
export const UploadSectionUnderReview: FC<IUploadSectionUnderReviewProps> = (props) => {
  const { data, children, defaultExpanded = false } = props;

  return (
    <UploadSection
      defaultExpanded={defaultExpanded}
      header={
        <div className='flex items-center gap-16'>
          <div className='w-[28px] h-[28px] rounded-full bg-[#EBEEF0] border border-[#597282] flex items-center justify-center'>
            <img src={IconInfo} className='w-[16px]' alt='' />
          </div>
          <div className='flex items-center gap-8'>
            <strong className='text-16 font-bold'>Under Review</strong>
            <div className='w-4 h-4 bg-blue_grey-30 rounded-full' />
            <div className='text-14 font-bold'>{data?.length} items</div>
          </div>
        </div>
      }
    >
      {children}
    </UploadSection>
  );
};
