import { FC, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { keyframes } from '@emotion/react';
import { cn } from 'src/utils/utility';
import IconRefresh from 'src/assets/images/icons/icon-refresh.svg';
import { LandingSectionName, ILandingMenuProps, RefreshIconProps } from './types';

// Define keyframes for the rotate animation
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RefreshIcon = styled('img')<RefreshIconProps>(({ refreshing }) => ({
  ...(refreshing && {
    animation: `${rotate} 2s linear infinite`,
  }),
}));

const StyledTabs = styled(Tabs)({
  minHeight: 0,
  '& .MuiButtonBase-root': {
    color: '#808080',
  },
  '& .MuiButtonBase-root.Mui-selected': {
    color: '#202020',
  },
  '& .MuiTouchRipple-root': {
    color: '#00992B',
  },
  '& .MuiTabs-indicator': {
    height: 2,
  },
  '& .MuiTabs-flexContainer': {
    justifyContent: 'center',
  },
});

export const LandingMenu: FC<ILandingMenuProps> = (props) => {
  const { selected, onChangeMenu, onRefresh, auditTotal, analyticTotal, performanceTotal, availableSections = [] } = props;

  // State to control the refresh animation
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleChange = (event, newValue: string) => {
    onChangeMenu(newValue);
  };

  // Handle refresh: start animation, execute onRefresh, then stop animation
  const handleRefresh = () => {
    setIsRefreshing(true);
    Promise.resolve(onRefresh()).finally(() => {
      setIsRefreshing(false);
    });
  };

  const menuItems = useMemo(() => {
    const allItems = [
      {
        id: LandingSectionName.ALL,
        name: 'All',
        count: auditTotal + analyticTotal + performanceTotal,
      },
      {
        id: LandingSectionName.AUDIT,
        name: 'Audit',
        count: auditTotal,
      },
      {
        id: LandingSectionName.PERFORMANCE,
        name: 'Performance Assessment',
        count: performanceTotal,
      },
      {
        id: LandingSectionName.ANALYTIC,
        name: 'Reports & Analytics',
        count: analyticTotal,
      },
    ];

    // Filter menu items based on available sections
    if (availableSections.length > 0) {
      return allItems.filter((item) => availableSections.includes(item.id));
    }

    return allItems;
  }, [auditTotal, analyticTotal, performanceTotal, availableSections]);

  return (
    <div className='bg-white border-b border-x border-[#EBEDF1] rounded-b-24 shadow-[0_6px_20px_0_rgba(0,0,0,0.04)]'>
      <div className='flex items-center  max-w-[90rem] mx-auto px-[3%]'>
        <StyledTabs value={selected} onChange={handleChange}>
          {menuItems.map((item) => (
            <Tab
              value={item.id}
              key={`landing-menu-${item.id}`}
              className='!py-16 !px-32 group'
              label={
                <div className='flex flex-row items-center gap-10'>
                  <strong className='normal-case text-16 font-medium transition-all duration-300 group-hover:text-neutral-90'>{item.name}</strong>
                  <span
                    className={cn(
                      'text-14 rounded-full px-6 py-1 font-medium transition-all duration-300 group-hover:bg-primary group-hover:text-white',
                      selected === item?.id ? 'bg-primary text-white' : 'bg-neutral-20 text-neutral-50'
                    )}
                  >
                    {item.count}
                  </span>
                </div>
              }
            />
          ))}
        </StyledTabs>

        <div className='ml-auto'>
          <button
            type='button'
            onClick={handleRefresh}
            aria-label='Refresh content'
            className='w-40 h-40 rounded-6 border border-[#D0D5DD] flex items-center justify-center'
          >
            <RefreshIcon src={IconRefresh} alt='Refresh icon' className='w-18' refreshing={isRefreshing} />
          </button>
        </div>
      </div>
    </div>
  );
};
