import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { nameofFactory } from 'src/utils/nameofFactory';
import { AnalyticSearchRequestDto, FiltersDto } from 'src/generated/services/TFinancialApi';
import { FilterDrawer } from './FilterDrawer';
import IconArrow from '../../../../../assets/images/icons/arrow-left_ico.svg';
import Button from '../../../atom/button';
import IconImport from '../../../../../assets/images/icons/import-primary.svg';
import { filterAccordionsMapper } from '../analyticsMapper';
import { SaveReportModal } from './SaveReportModal';
import { ChartViewType } from '../sidebar/types';
import { FilterHeader } from '../../filter/FilterHeader';
import { RhfFieldInfo } from './RhfFieldInfo';

const nameof = nameofFactory<AnalyticSearchRequestDto>();

interface IAnalyticsHeaderProps {
  data: FiltersDto;
  selectedChartView?: ChartViewType;
  setDefaultFilters?: (object) => void;
  onScreenShot?: () => void;
}

export const AnalyticsHeader = ({ data, selectedChartView, setDefaultFilters, onScreenShot }: IAnalyticsHeaderProps) => {
  const { watch, resetField, formState } = useFormContext();
  const dateReference = watch(nameof('dateReference'));
  const [isShowModal, setIsShowModal] = useState(false);
  const [isFilterChanged, setIsFilterChanges] = useState(false);

  useEffect(() => {
    if (dateReference) {
      resetField(nameof('filters'));
    }
  }, [dateReference, resetField]);
  const filterAccordions = filterAccordionsMapper(data);

  return (
    <div className='sticky top-0 flex items-center gap-20 px-24 pt-24 pb-20 bg-white border-b border-neutral-20 z-10'>
      <div className='flex-1 flex items-center gap-18'>
        <Link to='/' className='w-40 h-40 rounded-full bg-neutral-10 border border-neutral-20 flex items-center justify-center'>
          <img src={IconArrow} alt='Back' />
        </Link>
        <div className='flex flex-col gap-4'>
          <RhfFieldInfo fieldName='name' defaultValue='New Report' infoClassname='text-24 font-bold text-blue_grey-80' />

          <RhfFieldInfo fieldName='description' defaultValue='Report Description' textSize='md' infoClassname='text-14 font-medium text-neutral-60' />
        </div>
      </div>

      <div className='ml-auto flex items-center gap-16'>
        <Button
          variant='primary'
          size='medium'
          className='!min-w-0 !h-36'
          disabled={!formState?.isDirty && !isFilterChanged}
          onClick={() => setIsShowModal(true)}
        >
          Save Report
        </Button>
        <button
          aria-label='import'
          type='button'
          className='w-36 h-36 flex items-center justify-center border border-neutral-20 rounded-6'
          onClick={onScreenShot}
        >
          <img src={IconImport} alt='' />
        </button>
        <div className='w-1 h-16 bg-neutral-20' />
        <FilterHeader onChangeFilter={() => {}} />
        <FilterDrawer filterAccordions={filterAccordions} onChangeFilterNew={() => setIsFilterChanges(true)} />
      </div>
      <SaveReportModal
        open={isShowModal}
        title='Save Report'
        onClose={() => {
          setIsShowModal(false);
        }}
        setDefaultFilters={setDefaultFilters}
        selectedChartView={selectedChartView}
      />
    </div>
  );
};
