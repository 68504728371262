import { Link } from 'react-router-dom';
import { FC, useCallback } from 'react';

import IconArrowRight from 'src/assets/images/icons/chevron-right.svg';
import IconDocument from 'src/assets/images/icons/document.svg';
import { beautifyEnum } from 'src/utils/utility';
import { IToastResultDTO } from './types';
import StatusChip from '../../dashboard/StatusChip';

export const RecalculationCard: FC<IToastResultDTO> = (props) => {
  const { workflowStatus, invoiceStatus, invoiceId, invoiceNumber, shipmentNumber, deviations } = props;

  const getInvoiceStatus = useCallback(() => {
    let status = 'New Status';

    switch (workflowStatus) {
      case 'RECALCULATION_READY_FOR_PAYMENT':
        status = 'Ready for Payment';
        break;
      case 'RECALCULATION_REJECT':
        status = 'Rejected';
        break;
      case 'RECALCULATION_RESOLVED':
        status = 'Resolved';
        break;
      case 'RECALCULATION_PROCUREMENT_REVIEW':
        status = 'pending Procurement Review';
        break;
      case 'RECALCULATION_OPERATION_REVIEW':
        status = 'pending Logistics Review';
        break;
      default:
        status = 'New Status';
        break;
    }

    return status;
  }, [workflowStatus]);

  const isShowDeviations = workflowStatus !== 'RECALCULATION_REJECT' && workflowStatus !== 'RECALCULATION_READY_FOR_PAYMENT';

  return (
    <div className='py-14 pl-20 pr-24 rounded-8 bg-white border border-neutral-20'>
      <div className='flex items-center gap-24'>
        <div className='w-36'>
          <img src={IconDocument} alt='' />
        </div>
        <div className='flex-1'>
          <h3 className='font-medium text-14 mb-2'>Inv No. {invoiceNumber}</h3>

          {shipmentNumber && (
            <h3 className='font-medium text-14 mb-2 text-neutral-60'>
              Ship No. <strong className='text-neutral-90'>{shipmentNumber}</strong> is {getInvoiceStatus()}
            </h3>
          )}

          {deviations && isShowDeviations && (
            <div className='mt-8 bg-neutral-10 rounded-full py-4 px-12 inline-flex items-center gap-12'>
              {Object.entries(deviations).map(([key, value]) => (
                <div key={`${invoiceId}-${key}`} className='text-12 flex items-center gap-12 after:w-1 after:h-10 after:bg-neutral-40 last:after:hidden'>
                  <span>
                    {value} {beautifyEnum(key)}
                  </span>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className='ml-auto flex items-center gap-16'>
          <StatusChip status={invoiceStatus} size='small' />

          <Link
            to={`/action-details/${invoiceId}?hideBack=1`}
            className='flex items-center gap-4 py-8 pl-16 pr-10 rounded-full text-neutral-70 font-medium text-14 border border-neutral-20 bg-white shadow-[0_2px_3px_0_rgba(0,0,0,0.1)]'
            target='_blank'
          >
            See Invoice
            <img src={IconArrowRight} alt='' />
          </Link>
        </div>
      </div>
    </div>
  );
};
