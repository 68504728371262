import React, { useEffect, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { PermissionType } from 'src/types/permission';
import { useHasPermission, useUserPermissionTypes } from 'src/use-cases/user/useUserPermissions';
import { PageLoader } from 'src/presentations/components/molecules/pageLoader/PageLoader';

interface RequirePermissionProps extends PropsWithChildren {
  permission: PermissionType;
}

const RequirePermission: React.FC<RequirePermissionProps> = ({ children, permission }) => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const navigate = useNavigate();
  const { hasPermission, isLoading } = useHasPermission(permission);

  // Prefetch permissions
  useUserPermissionTypes();

  useEffect(() => {
    if (authStatus === 'unauthenticated') {
      navigate('/login');
    }
  }, [authStatus, navigate]);

  // Redirect to forbidden page if user doesn't have permission
  useEffect(() => {
    if (!isLoading && !hasPermission) {
      navigate('/forbidden');
    }
  }, [hasPermission, isLoading, navigate]);

  if (authStatus === 'unauthenticated') {
    return null;
  }

  // Show loading state while permissions are being fetched
  if (isLoading) {
    return <PageLoader />;
  }

  // Only render children if user has permission
  return hasPermission ? <>{children}</> : null;
};

export default RequirePermission;
