import { FC, useEffect, useRef, useState } from 'react';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

import { ResultItem } from './ResultItem';
import { ISearchBarResultsProps } from './types';

const IOSSwitch = styled((props: SwitchProps) => <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export const SearchBarResults: FC<ISearchBarResultsProps> = (props) => {
  const { contentRef, results, totalRecord, isShowContent, onLoadMore, hasMore, isLoading, isOnlyShowApproved, onChangeOnlyShowApproved, keyword } = props;
  const [searchTimestamp, setSearchTimestamp] = useState(0);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const observerTarget = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSearchTimestamp(Date.now());
  }, [results]);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !isLoading) {
          onLoadMore();
        }
      },
      { threshold: 0.1 }
    );

    if (observerTarget.current) {
      observerRef.current.observe(observerTarget.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [onLoadMore, hasMore, isLoading, isShowContent, searchTimestamp]);

  if (!isShowContent) {
    return null;
  }

  return (
    <div
      ref={contentRef}
      className='absolute top-[48px] left-0 w-[640px] bg-white text-blue_grey-90 border border-[#DFDFDF] rounded-8 z-[30] flex flex-col max-h-[80vh] shadow-custom'
    >
      <div className='flex items-center gap-48 px-16 py-12 bg-neutral-10 border-b border-neutral-20'>
        <h3 className='text-blue_grey-90 text-14'>{totalRecord ?? 0} Items found</h3>

        <div className='ml-auto flex items-center gap-8'>
          <p className='text-neutral-60 text-14'>Only Show Approved Invoice</p>
          <IOSSwitch
            checked={isOnlyShowApproved}
            onChange={() => {
              onChangeOnlyShowApproved(!isOnlyShowApproved);
            }}
          />
        </div>
      </div>

      {results?.length > 0 ? (
        <div className='flex-1 flex flex-col gap-16 px-8 pt-8 pb-16 overflow-y-auto' key={searchTimestamp}>
          {results.map((result) => (
            <ResultItem key={`result-${searchTimestamp}-${result?.invoiceId}`} result={result} keyword={keyword} />
          ))}
          <div ref={observerTarget} style={{ height: '30px' }} />
        </div>
      ) : (
        <div className='flex items-center justify-center p-32 text-center'>
          <p className='text-neutral-60 text-14 font-medium uppercase'>No results</p>
        </div>
      )}
    </div>
  );
};
