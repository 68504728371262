/**
 * Template Root
 */
import { ReactNode } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import PageError from 'src/containers/PageError';
import { UploadInvoice } from '../molecules/uploadInvoice/Upload';

import { ToastResult } from '../molecules/toast/ToastResult';
import { ModalContact } from '../molecules/contact/ModalContact';
import { RecalculationToastSelector } from '../organisms/actionDetails/recalculationToast/RecalculationToastSelector';
import { RecalculationToast } from '../organisms/actionDetails/recalculationToast/RecalculationToast';

const TemplateRoot = ({ children }: { children: ReactNode }) => (
  <ErrorBoundary fallback={<PageError />}>
    <RecalculationToastSelector>
      {children}
      <UploadInvoice />
      <ToastResult />
      <ModalContact />
      <RecalculationToast />
    </RecalculationToastSelector>
  </ErrorBoundary>
);

export default TemplateRoot;
