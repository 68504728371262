import { useState, useEffect, useCallback, useMemo } from 'react';
import { useGetServiceProvider } from 'src/use-cases/serviceProvider/useServiceProvider';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { useHasPermission } from 'src/use-cases/user/useUserPermissions';
import { PermissionType } from 'src/types/permission';
import { useChartReports } from '../../organisms/analytics/useAnalytics';
import { LandingPerformance } from '../../organisms/landing/Performance';
import { LandingTopWidget, LandingTopContent, LandingMenu, LandingAudit, LandingAnalytics, LandingSectionName } from '../../organisms/landing';

export const LandingPage = () => {
  const [tab, setTab] = useState<string>(LandingSectionName.ALL);

  const { data, isLoading: isLoadingProviders, refetch, isFetching } = useGetServiceProvider();

  const { data: analyticsData } = useChartReports();
  const [analyticsCount, setAnalyticsCount] = useState(0);

  const providers = data?.data || [];
  const [performanceCount, setPerformanceCount] = useState(0);
  const handlePerformanceCount = useCallback((count) => {
    setPerformanceCount(count);
  }, []);

  // Check permissions for each section
  const { hasPermission: hasAuditPermission } = useHasPermission(PermissionType.AUDIT);
  const { hasPermission: hasPerformancePermission } = useHasPermission(PermissionType.PERFORMANCE);
  const { hasPermission: hasReportPermission } = useHasPermission(PermissionType.REPORT);

  // Determine which sections to display based on permissions and selected tab
  const isDisplayAudit = hasAuditPermission && (tab === LandingSectionName.ALL || tab === LandingSectionName.AUDIT);
  const isDisplayPerformance = hasPerformancePermission && (tab === LandingSectionName.ALL || tab === LandingSectionName.PERFORMANCE);
  const isDisplayAnalytics = hasReportPermission && (tab === LandingSectionName.ALL || tab === LandingSectionName.ANALYTIC);

  // Create filtered menu items based on permissions
  const availableSections = useMemo(() => {
    const sections = [];

    // Always include ALL
    sections.push(LandingSectionName.ALL);

    // Add other sections based on permissions
    if (hasAuditPermission) sections.push(LandingSectionName.AUDIT);
    if (hasPerformancePermission) sections.push(LandingSectionName.PERFORMANCE);
    if (hasReportPermission) sections.push(LandingSectionName.ANALYTIC);

    return sections;
  }, [hasAuditPermission, hasPerformancePermission, hasReportPermission]);

  // Set default tab to first available section if current tab is not available
  useEffect(() => {
    if (availableSections.length > 0 && !availableSections.includes(tab)) {
      setTab(availableSections[0]);
    }
  }, [availableSections, tab]);

  useEffect(() => {
    if (analyticsData) {
      setAnalyticsCount(analyticsData.length);
    }
  }, [analyticsData]);

  const handleChange = (newValue: string) => {
    setTab(newValue);
  };

  const formContext = useForm({
    defaultValues: {
      auditSort: 'ACTION_NEEDED',
      analyticSort: 'desc',
    },
  });

  return (
    <FormContainer formContext={formContext}>
      <div className='relative bg-white'>
        <LandingTopWidget />
        <LandingTopContent />
        <LandingMenu
          onChangeMenu={handleChange}
          selected={tab}
          auditTotal={hasAuditPermission ? providers?.length || 0 : 0}
          analyticTotal={hasReportPermission ? analyticsCount : 0}
          performanceTotal={hasPerformancePermission ? performanceCount : 0}
          availableSections={availableSections}
          onRefresh={refetch}
        />

        <div className='pt-40 pb-64 flex flex-col gap-48 max-w-[90rem] mx-auto px-3%'>
          {isDisplayAudit && <LandingAudit providers={providers} isLoading={isLoadingProviders || isFetching} />}
          {isDisplayPerformance && <LandingPerformance onCountUpdate={handlePerformanceCount} />}
          {isDisplayAnalytics && <LandingAnalytics />}
        </div>
      </div>
    </FormContainer>
  );
};
