import Chip from '@mui/material/Chip';

interface StatusChipProps {
  status: string;
  size?: 'small' | 'medium';
}

const commonStyle = {
  borderRadius: '20px',
  fontWeight: 500,
};

const statusConfig: Record<string, { label: string; style: React.CSSProperties }> = {
  APPROVED: {
    label: 'Ready for Payment',
    style: {
      background: 'rgba(0, 153, 43, 0.12)',
      border: '1px solid #00992B',
      ...commonStyle,
      color: '#00992B',
    },
  },
  TO_APPROVE: {
    label: 'To Approve',
    style: {
      background: 'rgba(38, 158, 204, 0.2)',
      border: '1px solid #269ECC',
      ...commonStyle,
      color: '#1D7699',
    },
  },
  TO_REJECT: {
    label: 'To Reject',
    style: {
      background: '#DFDFDF',
      border: '1px solid #475B69',
      ...commonStyle,
      color: '#475B69',
    },
  },
  REJECTED: {
    label: 'Rejected',
    style: {
      background: 'rgba(231, 37, 71, 0.20)',
      border: '1px solid #E72547',
      ...commonStyle,
      color: '#E72547',
    },
  },
  PENDING_DOCUMENTS: {
    label: 'Pending Document',
    style: {
      background: 'rgba(253, 202, 64, 0.20)',
      border: '1px solid #FDCA40',
      ...commonStyle,
      color: '#E3B539',
    },
  },
  PENDING_DOCUMENT: {
    label: 'Pending Document',
    style: {
      background: 'rgba(253, 202, 64, 0.20)',
      border: '1px solid #FDCA40',
      ...commonStyle,
      color: '#E3B539',
    },
  },
  FLAGGED: {
    label: 'Flagged',
    style: {
      background: 'rgba(247, 152, 36, 0.2)',
      border: '1px solid #F79824',
      ...commonStyle,
      color: '#E58D21',
    },
  },
  NO_CONTRACT_FOUND: {
    label: 'Flagged',
    style: {
      background: 'rgba(247, 152, 36, 0.2)',
      border: '1px solid #F79824',
      ...commonStyle,
      color: '#E58D21',
    },
  },
  NOT_AN_INVOICE: {
    label: 'Not an Invoice',
    style: {
      background: '#DFDFDF',
      border: '1px solid #808080',
      ...commonStyle,
      color: '#808080',
    },
  },
  MISSING_REQUIRED_FIELD: {
    label: 'Missing Required Fields',
    style: {
      background: 'rgb(255, 255, 255)',
      border: '1px solid #E72547',
      ...commonStyle,
      color: '#E72547',
    },
  },
  RESOLVED_AS_NOT_AN_INVOICE: {
    label: 'Not An Invoice',
    style: {
      background: '#DFDFDF',
      border: '1px solid #808080',
      ...commonStyle,
      color: '#808080',
    },
  },
  RESOLVED_AS_INCONSISTENT_SHIPMENT_DATA: {
    label: 'Inconsistent Shipment Data',
    style: {
      background: 'rgb(255, 255, 255)',
      border: '1px solid #F79824',
      ...commonStyle,
      color: '#F79824',
    },
  },
  RESOLVED_AS_MISSING_REQUIRED_FIELD: {
    label: 'Missing Required Fields',
    style: {
      background: 'rgb(255, 255, 255)',
      border: '1px solid #E72547',
      ...commonStyle,
      color: '#E72547',
    },
  },
};

const StatusChip: React.FC<StatusChipProps> = ({ status, size = 'medium' }) => {
  const config = statusConfig[status] || { label: '', style: {} };
  return <Chip label={config.label} style={config.style} size={size} />;
};

export default StatusChip;
