import { UseQueryResult, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { LoggedInUserDto, ResponseDtoSetPermission } from 'src/generated/services/TFinancialApi';
import { tFinancialService } from 'src/infra/services/tFinancialService';
import { cacheKeysUtil } from 'src/utils/cacheKeysUtil';
import { PermissionType } from 'src/types/permission';

export const useUserDetails = (): UseQueryResult<LoggedInUserDto> =>
  useQuery(cacheKeysUtil.userDetails(), async (): Promise<LoggedInUserDto> => {
    const response = await tFinancialService.api.getLoggedInUserDetails();
    const { data } = response;
    return data?.data;
  });

// Original hook for backward compatibility
export const useUserPermissions = (): UseQueryResult<ResponseDtoSetPermission> =>
  useQuery(cacheKeysUtil.userPermission(), async (): Promise<ResponseDtoSetPermission> => {
    const response = await tFinancialService.api.getLoggedInUserPermissions();
    const { data } = response;
    return data;
  });

// New hook for permission types with query options
export const useUserPermissionTypes = (options?: UseQueryOptions<PermissionType[]>): UseQueryResult<PermissionType[]> =>
  useQuery<PermissionType[]>(
    cacheKeysUtil.userPermissionTypes(),
    async (): Promise<PermissionType[]> => {
      const response = await tFinancialService.api.getLoggedInUserPermissionTypes();
      // Convert the string array to PermissionType array with type assertion
      return (response.data?.data || []) as PermissionType[];
    },
    {
      // Default options for better performance
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      ...options, // Allow overriding with custom options
    }
  );

// Hook to check if user has a specific permission
export const useHasPermission = (
  requiredPermission: PermissionType
): {
  hasPermission: boolean;
  isLoading: boolean;
} => {
  const { data: permissions, isLoading } = useUserPermissionTypes({
    // Override default options to ensure this hook is very responsive to changes
    staleTime: 0, // Consider data always stale
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  // While loading, we don't know yet if the user has permission
  // Return isLoading: true so the component can show a loading state
  if (isLoading) {
    return { hasPermission: false, isLoading: true };
  }

  // If we have permissions data, check if the user has the required permission
  return {
    hasPermission: permissions?.includes(requiredPermission) || false,
    isLoading: false,
  };
};
