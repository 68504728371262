import { useUserPermissions, useUserDetails } from 'src/use-cases/user/useUserPermissions';
import IconReceipt from 'src/assets/images/icons/receipt-alt-primary.svg';
import TopDecoImage from 'src/assets/images/landing-top-deco.png';
import LogoImg from 'src/assets/images/tetrixx.svg';
import Button from '../../atom/button';

export const LandingTopContent = () => {
  useUserPermissions();

  const { data: userDetails, isLoading } = useUserDetails();

  const isSystemAdmin = userDetails?.systemAdmin;
  const isAdmin = userDetails?.admin;
  const isHaveAccessInternal = !isLoading && isSystemAdmin;
  const isHaveAccessRateCard = !isLoading && isAdmin && userDetails?.displayRole === 'Procurement Manager';

  return (
    <>
      <div className='relative bg-neutral-5 pt-[78px] pb-[66px] border-b-2 border-neutral-20'>
        <div className='absolute top-0 right-0'>
          <img src={TopDecoImage} alt='' />
        </div>
        <div className='relative z-10 flex items-center gap-30 max-w-[90rem] mx-auto px-[3%]'>
          <h1 className='text-32 font-semibold mb-8 text-neutral-80'>
            Welcome to your Freight Invoice <br /> Audit & Analytics Platform
          </h1>

          <div className='ml-auto'>
            <div className='flex items-center gap-12'>
              {isHaveAccessInternal && (
                <Button variant='neutral_outline' path='/internal-dashboard'>
                  <div className='flex items-center gap-6'>
                    <img alt='tetrixx' src={LogoImg} className='w-16' />
                    Internal Dashboard
                  </div>
                </Button>
              )}
              {isHaveAccessRateCard && (
                <Button variant='dark' path='/ratecard-manager'>
                  <div className='flex items-center gap-6'>
                    <img src={IconReceipt} alt='' />
                    Open Rate Card Manager
                  </div>
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
