import { useMemo, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Modal from 'src/presentations/components/molecules/modal';

import IconUpload from 'src/assets/images/icons/document-uploaded.svg';
import { useGetShipment } from 'src/use-cases/invoice/useInvoices';
import { useRecalculationToastSelector } from './RecalculationToastSelector';
import { IToastResultDTO } from './types';
import { RecalculationCard } from './RecalculationCard';

const StyledModal = styled(Modal)({
  zIndex: 9999,

  '& .MuiDialog-paper': {
    maxWidth: '1000px',
    height: 'calc(100% - 64px)',
    borderRadius: '16px',

    '& .MuiDialogContent-root': {
      padding: '0',
      backgroundColor: '#F5F6FA',
    },
  },
});

export const RecalculationToast = () => {
  const [isShowDetails, setIsShowDetails] = useState(false);
  const { isShowNotification, currentShipmentInvoiceId, closeToast } = useRecalculationToastSelector();

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const startAutoHideTimer = (delay: number) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        closeToast();
      }, delay);
    };

    if (isShowNotification && !isShowDetails) {
      // Start 10 second timer when notification shows and details not open
      startAutoHideTimer(10000);
    } else if (isShowNotification && isShowDetails) {
      // Clear timer when details are open
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [isShowNotification, isShowDetails, closeToast]);

  const { data: shipmentInAction } = useGetShipment(currentShipmentInvoiceId && isShowNotification ? currentShipmentInvoiceId : null);

  const affectedItems = useMemo(() => {
    const items: IToastResultDTO[] = [];
    if (shipmentInAction && shipmentInAction?.backgroundShipmentPerformResultDto) {
      const affectedData = shipmentInAction.backgroundShipmentPerformResultDto?.affectedShipmentAndInvoice;

      if (affectedData?.affectedInvoices?.length > 0) {
        affectedData?.affectedInvoices.forEach((item) => {
          items.push({
            invoiceId: item?.invoiceId,
            invoiceNumber: item?.invoiceNumber,
            invoiceStatus: item?.invoiceStatus,
            workflowStatus: item?.invoiceStatus === 'APPROVED' ? 'RECALCULATION_READY_FOR_PAYMENT' : 'RECALCULATION_REJECT',
          });
        });
      }
      if (affectedData?.flaggedShipmentInvoices?.length > 0) {
        affectedData?.flaggedShipmentInvoices.forEach((item) => {
          items.push({
            invoiceId: item?.invoiceId,
            invoiceNumber: item?.invoiceNumber,
            shipmentNumber: item?.shipmentNumber,
            invoiceStatus: item?.status,
            workflowStatus: `RECALCULATION_${item?.currentDeviationWorkflow}`,
            deviations: item?.deviationTypeCountMap,
          });
        });
      }
      if (affectedData?.resolvedShipmentInvoices?.length > 0) {
        affectedData?.resolvedShipmentInvoices.forEach((item) => {
          items.push({
            invoiceId: item?.invoiceId,
            invoiceNumber: item?.invoiceNumber,
            shipmentNumber: item?.shipmentNumber,
            invoiceStatus: item?.status,
            workflowStatus: `RECALCULATION_RESOLVED`,
            deviations: item?.deviationTypeCountMap,
          });
        });
      }
    }
    return items;
  }, [shipmentInAction]);

  if (!isShowNotification || affectedItems?.length === 0) {
    return null;
  }

  return (
    <>
      {isShowNotification && (
        <div className='fixed bottom-16 left-16 z-[9999] px-16 py-10 rounded-6 bg-[#202020] text-white flex items-center'>
          <img src={IconUpload} alt='' />
          <div className='flex items-center pl-5 pr-40'>{affectedItems?.length ?? 0} Shipments impacted after recalculations</div>
          <div className='w-1 h-16 bg-neutral-70 mr-10' />
          <button type='button' className='pr-20 flex items-center gap-10 text-green-70 font-semibold' onClick={() => setIsShowDetails(true)}>
            Show Details
          </button>
          <button
            type='button'
            aria-label='dialog box'
            className='font-medium text-primary text-14'
            onClick={() => {
              closeToast();
            }}
          >
            <CloseIcon sx={{ color: '#808080' }} />
          </button>
        </div>
      )}
      <StyledModal fullWidth maxWidth='md' open={isShowDetails} onClose={() => setIsShowDetails(false)}>
        <div className='relative bg-neutral-10 flex flex-col h-full'>
          <div className='px-24 pb-20 pt-24 border-b border-neutral-20 bg-white'>
            <h3 className='text-18 font-bold mt-12'>{affectedItems?.length ?? 0} Shipments Impacted after Recalculations</h3>
            <p className='text-14 text-blue_grey-80 font-medium mt-5'> </p>
          </div>

          <div className='flex-1 p-24 overflow-auto flex flex-col gap-10'>
            {affectedItems.map((item) => (
              <div key={`affected-item-${item?.invoiceId}`} className=''>
                <RecalculationCard {...item} />
              </div>
            ))}
          </div>

          <div className='mt-auto px-24 py-20 border-t border-neutral-20 flex items-center justify-end gap-12 bg-white'>
            <Button
              type='button'
              variant='outlined'
              color='inherit'
              onClick={() => setIsShowDetails(false)}
              sx={{ textTransform: 'capitalize', borderColor: '#dfdfdf' }}
            >
              Close
            </Button>
          </div>
        </div>
      </StyledModal>
    </>
  );
};
