import { useState, useEffect, useCallback } from 'react';
import { PDFDocument } from 'pdf-lib';

interface UsePdfProcessorOptions<T> {
  id?: string;
  idName: string;
  fetchFunction: () => Promise<{ data?: T }>;
  errorPrefix: string;
  pdfTitle?: string;
  processData?: boolean;
}

export const usePdfProcessor = <T extends string | Blob | ArrayBuffer>({
  id,
  idName,
  fetchFunction,
  errorPrefix,
  pdfTitle,
  processData = false,
}: UsePdfProcessorOptions<T>) => {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const processPdfData = useCallback(async () => {
    // Clean up previous URL if it exists
    if (pdfUrl) {
      URL.revokeObjectURL(pdfUrl);
      setPdfUrl(null);
    }

    setIsLoading(true);
    setError(null);

    try {
      if (!id) {
        throw new Error(`${idName} not provided`);
      }

      const result = await fetchFunction();
      const pdfData = result?.data;

      if (!pdfData) {
        throw new Error(`No data returned from ${errorPrefix} fetch`);
      }

      let finalData: ArrayBuffer;

      // Process the data based on its type
      if (processData) {
        if (typeof pdfData === 'string') {
          const binaryString = atob(pdfData as string);
          const len = binaryString.length;
          const bytes = new Uint8Array(len);
          for (let i = 0; i < len; i += 1) {
            bytes[i] = binaryString.charCodeAt(i);
          }
          finalData = bytes.buffer;
        } else if (pdfData instanceof Blob) {
          finalData = await (pdfData as Blob).arrayBuffer();
        } else if (pdfData instanceof ArrayBuffer) {
          finalData = pdfData as ArrayBuffer;
        } else {
          throw new Error('Invalid PDF data format');
        }

        // If a title is provided, modify the PDF
        if (pdfTitle) {
          const pdfDoc = await PDFDocument.load(finalData);
          pdfDoc.setTitle(pdfTitle);
          finalData = await pdfDoc.save();
        }
      } else {
        // Simple case - just use the data directly
        // Use if-else-if chain instead of nested ternary
        const handleData = () => {
          if (pdfData instanceof ArrayBuffer) {
            return pdfData as ArrayBuffer;
          }
          if (pdfData instanceof Blob) {
            return (pdfData as Blob).arrayBuffer();
          }
          return Promise.resolve(new TextEncoder().encode(String(pdfData)).buffer);
        };

        finalData = await handleData();
      }

      const url = URL.createObjectURL(new Blob([finalData], { type: 'application/pdf' }));

      setPdfUrl(url);
      setError(null);
    } catch (error) {
      console.error('Error processing PDF:', error);
      setError(error instanceof Error ? error.message : 'Failed to load PDF');
    } finally {
      setIsLoading(false);
    }
    // Remove pdfUrl from the dependency array to prevent infinite loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, idName, fetchFunction, errorPrefix, pdfTitle, processData]);

  useEffect(() => {
    processPdfData();

    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processPdfData]);

  return {
    pdfUrl,
    error,
    isLoading,
    processPdfData,
  };
};
