import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { PageLoader } from 'src/presentations/components/molecules/pageLoader/PageLoader';

// Configure PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`;

interface PdfViewerComponentProps {
  pdfUrl: string;
  onError: (errorMessage: string) => void;
  downloadFileName: string;
}

const PdfViewerComponent = ({ pdfUrl, onError, downloadFileName }: PdfViewerComponentProps) => {
  const [numPages, setNumPages] = useState<number | null>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const handleDownload = () => {
    if (pdfUrl) {
      const link = document.createElement('a');
      link.href = pdfUrl;

      // Format current date as YYYY-MM-DD
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;

      link.download = `${downloadFileName}-${formattedDate}.pdf`;
      link.click();
    }
  };

  return (
    <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ padding: '10px', display: 'flex', justifyContent: 'center', gap: '10px', alignItems: 'center' }}>
        <p style={{ margin: '0 10px' }}>{numPages ? `Total Pages: ${numPages}` : 'Loading...'}</p>
        <button
          onClick={handleDownload}
          style={{
            marginLeft: '20px',
            padding: '5px 10px',
            backgroundColor: '#28a745',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Download
        </button>
      </div>
      <div
        style={{
          width: '100%',
          height: 'calc(100% - 50px)',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px 0',
        }}
      >
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => {
            console.error('Error loading PDF:', error);
            onError('Failed to load PDF document. Please try again later.');
          }}
          loading={<PageLoader />}
          error={
            <div style={{ padding: '20px', backgroundColor: '#f8d7da', color: '#721c24', borderRadius: '4px' }}>
              <p>Failed to load PDF. Please try again later.</p>
            </div>
          }
        >
          {Array.from(new Array(numPages), (_, index) => (
            <div key={`page_${index + 1}`} style={{ marginBottom: '20px' }}>
              <Page
                pageNumber={index + 1}
                width={Math.min(window.innerWidth * 0.9, 800)}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                error={
                  <div style={{ padding: '20px', backgroundColor: '#f8d7da', color: '#721c24', borderRadius: '4px' }}>
                    <p>Failed to load page {index + 1}. Please try another page.</p>
                  </div>
                }
              />
            </div>
          ))}
        </Document>
      </div>
    </div>
  );
};

export default PdfViewerComponent;
