import { useFormContext } from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import clsx from 'clsx';

import IconEdit from '../../../../../assets/images/icons/edit-filled.svg';

const CustomTextField = styled(TextField, { shouldForwardProp: (prop) => prop !== 'textSize' })(({ textSize = 'lg' }: { textSize?: 'lg' | 'md' }) => ({
  width: '100%',
  '& .MuiInputBase-input': {
    fontSize: textSize === 'lg' ? 24 : 14,
    fontWeight: textSize === 'lg' ? 'bold' : 'medium',
    color: textSize === 'lg' ? '#333333' : '#808080',
    padding: 0,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
      borderBottom: '1px solid #bbbbbb',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
}));

interface IRhfFieldInfoProps {
  fieldName: 'name' | 'description';
  textSize?: 'lg' | 'md';
  infoClassname: string;
  defaultValue: string;
}

export const RhfFieldInfo = ({ fieldName, defaultValue, infoClassname, textSize = 'lg' }: IRhfFieldInfoProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { register, watch, setValue, formState } = useFormContext();
  const fieldValue = watch(fieldName);
  const tempTextRef = useRef<HTMLDivElement>();
  const [isShowField, setIsShowField] = useState(false);
  const [inputWidth, setInputWidth] = useState(0);
  const [tempInputValue, setTempInputValue] = useState('');

  useEffect(() => {
    if (tempTextRef?.current) {
      setInputWidth(tempTextRef.current.offsetWidth);
    }
  }, [fieldValue, tempTextRef, isShowField]);

  // Initialize temp input value when edit mode is activated
  useEffect(() => {
    if (isShowField) {
      setTempInputValue(fieldValue || '');
    }
  }, [isShowField, fieldValue]);

  const handleAccept = () => {
    // Only update the form value when user accepts the change
    if (tempInputValue !== fieldValue) {
      setValue(fieldName, tempInputValue, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
    setIsShowField(false);
  };

  const handleKeyDown = (evt) => {
    if (evt.key === 'Enter') {
      handleAccept();
    } else if (evt.key === 'Escape') {
      // Cancel edit and revert to original value
      setIsShowField(false);
    }
  };

  return (
    <div className='flex items-center gap-16 relative'>
      {isShowField ? (
        <>
          <div style={{ width: `${inputWidth + 10}px`, minWidth: '50px' }}>
            <CustomTextField
              name=''
              size='small'
              textSize={textSize}
              value={tempInputValue}
              onChange={(e) => setTempInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
              autoFocus
            />
          </div>
          <div className='relative z-10 flex items-center justify-center'>
            <button aria-label='submit-title' type='button' onClick={handleAccept} className='flex items-center justify-center'>
              <CheckCircleIcon sx={{ color: '#999999', fontSize: 18 }} />
            </button>
          </div>
          <div ref={tempTextRef} className={clsx(infoClassname, 'absolute top-0 left-0 opacity-0 invisible')}>
            {fieldValue}
          </div>
        </>
      ) : (
        <>
          <p className={infoClassname}>{fieldValue?.length === 0 ? defaultValue : fieldValue}</p>
          <div className='relative z-10 flex items-center justify-center'>
            <button aria-label='submit-desc' type='button' onClick={() => setIsShowField(true)}>
              <img src={IconEdit} alt='' />
            </button>
          </div>
        </>
      )}
    </div>
  );
};
